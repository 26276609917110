import React from 'react';
import '../styles/Product.css'; // Import your CSS file for styling

const Product = () => {
  return <>
    <div className="contact-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
     
      <div className="content">PRODUCTS</div>
        <div className="navigation">
        
          <span>HOME</span> / <span className='contact'>PRODUCTS</span>
        
      </div>
      
    </div>

    
<div className="flex-container2">
<div className="products-heading2">
    <h2>PRODUCTS</h2>
  </div>
  <div className='flex2'>

        <div className="column2">
         
          <div className="product2">
            <h3>BELT SCALPERS</h3>
            <p>FLO-LINE PRIMER</p>
            <p>FLO-LINE PRIMER II</p>
          </div>
          <div className="product2">
            <h3>PUMPS</h3>
            <p>CENTRIFUGAL PUMP</p>
          </div>
          <div className="product2">
            <h3>CENTRIFUGES</h3>
            <p>FULL HYDRAULIC DRIVE</p>
            <p>GEADBOX DRIVE</p>
            <p>VARIABLE FREQUENCY DRIVE</p>
          </div>
          <div className="product2">
            <h3>DEGASSERS</h3>
            <p>ATMOSPHERIC DEGASSER</p>
            <p>VACUUM DEGASSER</p>
          </div>
        </div>
        <div className="column2">
          
          <div className="product2">
            <h3>DRY SCREENING MACHINES</h3>
            <p>FRONT-TO-BACK DRY MACHINES</p>
            <p>TRADITIONAL DRY MACHINES</p>
            
          </div>
          <div className="product2">
            <h3>FLOW DISTRIBUTORS</h3>
            <p>SHAKER FLO-DIVIDER</p>
            <p>WET SCREENING FLO-DRIVER</p>
          </div>
          <div className="product2">
            <h3>HYDROCYCLONES</h3>
            <p>DESANDERS</p>
            <p>DESILTERS</p>
          </div>
          <div className="product2">
            <h3>URETHANE INTERSTAGE SCREENS</h3>
            <p>G-VAULT URETHANE INTERSTAGE SCREEN</p>
          </div>
          <div className="product2">
            <h3>MUD AGITATORS</h3>
            <p>MUD AGITATOR</p>
            </div>
            <div className="product2">
            <h3>MUD HOPPER</h3>
            <p>MUD HOPPER</p>
            </div>
        </div>
       
        <div className="column2">
          
          <div className="product2">
            <h3>SCREEN MEDIA</h3>
            <p>API RP 13C (ISO 13501) COMPLIANT</p>
            <p>SCREENS</p>
            
          </div>
          <div className="product2">
            <h3>SYNTHETIC SCREEN</h3>
            <p>POLYWEB SCREENS</p>
            <p>URETHANE SCALPING SCREENS</p>
           
          </div>
          <div className="product2">
            <h3>WOVEN WIRE SCREENS</h3>
            <p>FLAT SCREENS</p>
            <p>PYRAMID SCREENS</p>
            <p>SANDWICH SCREENS</p>
           
          </div>
          <div className="product2">
          <h3>SHALE SHAKERS</h3>
          <p>DUAL POOL 600 SERIES</p>
          <p>FLO-LINE CLEANER 2000</p>
          <p>FLO-LINE CLEANER 400</p>
          <p>FLO-LINE CLEANER 500</p>
          <p>HYPERPOOL SERIES</p>
          <p>HYPERPOOL KITS</p>  
          </div>
        </div>
        <div className="column2">
         
          <div className="product2">
            <h3>TANK SYSTEMS</h3>
            <p>FINES RECOVERY SYSTEM</p>
            <p>MINI SEPARATION SYSTEM</p>
            <p>MODULAR SLURRY SEPARATION PLANT</p>
            
          </div>
          <div className="product2">
            <h3>VIBRATORY MOTORS</h3>
            <p>SUPER G</p>
            <p>SUPER G2</p>
            <p>SUPER G3</p>
            <p>W MOTOR</p>
          
          </div>
          <div className="product2">
            <h3>WET SCREENING & DEWATERING MACHINES</h3>
            
            <p>HI-G DEWATERING MACHINE</p>
            <p>HIGH SHEAR SCREEN</p>
            <p>LINEAR MOTION</p>
            <p>MULTIFEED</p>
            
            <p>STACK SIZER</p>
            <p>SUPERSTACK</p>
            <p>HI-CAP</p>
            <p>REPULP</p>
            
          </div>
        </div>
        </div>
      </div>
    
    </>;
}

export default Product;
