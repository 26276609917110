
// // // // import React, { useState } from 'react';
// // // // import '../styles/Services.css';
// // // // import { Link } from 'react-router-dom';
// // // // import Slider from 'react-slick';
// // // // import img1 from '../assets/fin.jpg'
// // // // import 'slick-carousel/slick/slick.css';
// // // // import 'slick-carousel/slick/slick-theme.css';

// // // // const Services = () => {
// // // //   const [selectedService, setSelectedService] = useState(null);

// // // //   const handleServiceClick = (serviceName) => {
// // // //     setSelectedService(serviceName);
// // // //   };

// // // //   const handleBackClick = () => {
// // // //     setSelectedService(null);
// // // //   };

// // // //   const carouselSettings = {
// // // //     dots: false,
// // // //     infinite: false,
// // // //     speed: 500,
// // // //     slidesToShow: 1,
// // // //     slidesToScroll: 1,
// // // //     autoplay: false,
// // // //   };

// // // //   return (
// // // //     <>
// // // //       <div className="contact-container">
// // // //       {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
// // // //         <div className="bg1"></div>
// // // //         <div className="overlay"></div>
        
// // // //       <div className="carousel-textt">
// // // //   <div class="content-container">
// // // //     <div class="content">SERVICES</div>
// // // //     <div class="navigation">
// // // //       <span>HOME</span> / <span class='contact'>SERVICES</span>
// // // //     </div>
// // // //   </div>
// // // //   <div class="arrow-box"></div>
// // // // </div>

       
// // // //       </div>
// // // //       <div className='glass1'>
// // // //         {selectedService ? (
// // // //           <div className="service-details">
// // // //             <h2>{selectedService}</h2>
// // // //             <Slider {...carouselSettings}>
// // // //               {/* Add service descriptions */}
// // // //               {selectedService === "Corporate Governance" && (
// // // //                 <>
// // // //                   <div>
// // // //                     <p>Business set up in UAE</p>
// // // //                     <p>Main land</p>
// // // //                     <p>Freezone</p>
// // // //                     <p>Offshore</p>
// // // //                     <p>Bank account in UAE</p>
// // // //                     <p>Listing of company with banks</p>
// // // //                     <p>Business tour to UAE</p>
// // // //                     <p>UAE Visa support</p>
// // // //                     <p>Resident visa by property</p>
// // // //                     <p>Relocation to UAE</p>
// // // //                     <p>ISO certifications</p>
// // // //                     <p>PRO services</p>
// // // //                     <p>Golden visa</p>
// // // //                   </div>
// // // //                 </>
// // // //               )}
// // // //               {selectedService === "Accounting & Finance" && (
// // // //                 <>
// // // //                   <div>
// // // //                     <p>Book keeping</p>
// // // //                     <p>Financial accounting & reporting</p>
// // // //                     <p>Internal audit</p>
// // // //                     <p>External audit</p>
// // // //                     <p>Internal control mechanism</p>
// // // //                     <p>Cost audit</p>
// // // //                     <p>VAT</p>
// // // //                     <p>Corporate tax</p>
// // // //                     <p>Corporate Finance</p>
// // // //                     <p>Fixed Asset Management</p>
// // // //                     <p>Business plans</p>
// // // //                     <p>Market studies</p>
// // // //                     <p>Feasibility studies</p>
// // // //                     <p>Cashflow management</p>
// // // //                   </div>
// // // //                 </>
// // // //               )}
// // // //               {selectedService === "HR Consultancy" && (
// // // //                 <>
// // // //                   <div>
// // // //                     <p>Recruitment services</p>
// // // //                     <p>Secondments</p>
// // // //                     <p>Payroll & SOS</p>
// // // //                     <p>Global mobility</p>
// // // //                     <p>Oil & gas professional & technical competency trainings</p>
// // // //                     <p>Contracting services</p>
// // // //                     <p>Insurance services management</p>
// // // //                     <p>End of service benefits management of employees</p>
// // // //                   </div>
// // // //                 </>
// // // //               )}
// // // //               {selectedService === "Trading & Provision of goods" && (
// // // //                 <>
// // // //                   <div>
// // // //                     <p>Complete office setup</p>
// // // //                     <p>Provision of furniture & fittings</p>
// // // //                     <p>Installation of software & hardware security systems</p>
// // // //                     <p>Installation of fire extinguishers & maintenance of fire alarms</p>
// // // //                     <p>General office supplies</p>
// // // //                     <p>HVAC maintenance services</p>
// // // //                   </div>
// // // //                 </>
// // // //               )}
// // // //             </Slider>
// // // //             <button className='btn' onClick={handleBackClick}>Back</button>
// // // //           </div>
// // // //         ) : (
// // // //           <div className="office-info1">
// // // //             <h2 className="title1">OGMCLLC</h2>
// // // //             <h2 className="title2">OGMCLLC Service Range</h2>
// // // //           </div>
// // // //         )}
// // // //         {!selectedService && (
// // // //           <div className="office">
// // // //             <p>
// // // //               <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}>1. Corporate Governance</Link>
// // // //               <br /><br />
// // // //               <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}>2. Accounting & Finance</Link>
// // // //               <br /><br />
// // // //               <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}>3. HR Consultancy</Link>
// // // //               <br /><br />
// // // //               <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}>4. Trading & Provision of goods</Link>
// // // //             </p>
// // // //           </div>
// // // //         )}
// // // //       </div>
// // // //     </>
// // // //   );
// // // // }

// // // // export default Services;


// // //   import React, { useState } from 'react';
// // //   import { FaBuilding, FaLandmark, FaMapMarkerAlt, FaShip, FaUniversity, FaListAlt, FaPlane, FaRegAddressCard, FaSuitcase, FaExchangeAlt, FaCertificate, FaClipboardCheck, FaMoneyBillAlt,  FaCalculator, FaMoneyCheck, FaPeopleCarry, FaHandshake, FaChalkboardTeacher, FaHammer, FaFileInvoiceDollar, FaCashRegister, FaBoxOpen, FaToolbox, FaBoxes} from 'react-icons/fa';

// // //   import '../styles/Services.css';
// // //   import { Link } from 'react-router-dom';
// // //   import Slider from 'react-slick';
// // //   import 'slick-carousel/slick/slick.css';
// // //   import 'slick-carousel/slick/slick-theme.css';

// // //   // Example icon imports (use your actual icons)
// // //   import CorporateIcon from '../assets/fin.jpg';
// // //   import FinanceIcon from '../assets/finance.jpg';
// // //   import HRIcon from '../assets/social.jpg';
// // //   import TradingIcon from '../assets/oil.jpg';

// // //   const Services = () => {
// // //     const [selectedService, setSelectedService] = useState(null);
// // //     const [activeIndex, setActiveIndex] = useState(null);

// // //     const handleServiceClick = (serviceName) => {
// // //       setSelectedService(serviceName);
// // //       setActiveIndex(null);
// // //     };

// // //     const handleBackClick = () => {
// // //       setSelectedService(null);
// // //     };

// // //     const handleItemClick = (index) => {
// // //       setActiveIndex(index);
// // //     };

// // //     const carouselSettings = {
// // //       dots: false,
// // //       infinite: false,
// // //       speed: 500,
// // //       slidesToShow: 1,
// // //       slidesToScroll: 1,
// // //       autoplay: false,
// // //     };


// // //     const services = {
    
// // //       "Oil and Gas Services": [
// // //         { name: "Regulatory understanding", icon: <FaBuilding className="icon1"/> },
// // //         { name: "Efficiency through cost optimization", icon: <FaCalculator className="icon1"/> },
// // //         { name: "Experienced people", icon: <FaPeopleCarry className="icon1"/> },
// // //         { name: "Strong safety record", icon: <FaClipboardCheck className="icon1"/> },
// // //         { name: "Risk management", icon: <FaFileInvoiceDollar className="icon1"/> },
// // //         { name: "We work at scale", icon: <FaBoxes className="icon1"/> },
// // //         { name: "Flexible and responsive organisation", icon: <FaExchangeAlt className="icon1"/> },
// // //         { name: "Well project management", icon: <FaChalkboardTeacher className="icon1"/> },
// // //         { name: "Asset management", icon: <FaHammer className="icon1"/> },
// // //         { name: "Performance consulting", icon: <FaListAlt className="icon1"/> },
// // //         { name: "Technical consulting", icon: <FaPlane className="icon1"/> },
// // //         { name: "Managed services", icon: <FaShip className="icon1"/> }
// // //       ]
// // //     };

// // //     return (
// // //       <>
// // //         <div className="contact-container">
// // //           <div className="bg1"></div>
// // //           <div className="overlay"></div>

// // //           <div className="carousel-textt">
// // //             <div className="content-container">
// // //               <div className="content">SERVICES</div>
// // //               <div className="navigation">
// // //                 <span>HOME</span> / <span className='contact'>SERVICES</span>
// // //               </div>
// // //             </div>
// // //             <div className="arrow-box"></div>
// // //           </div>
// // //         </div>
// // //         <div className='glass1'>
// // //           {selectedService ? (
// // //             <div className="service-details">
// // //               <h2>{selectedService}</h2>
// // //               {/* <div className="services-grid">
// // //                 {services[selectedService].map((service, index) => (
// // //                   <div 
// // //                     key={index} 
// // //                     className={`service-item ${activeIndex === index ? 'active' : ''}`} 
// // //                     onClick={() => handleItemClick(index)}
// // //                   >
// // //                     <img src={service.icon} alt={`${service.name} icon`} className={`service-icon ${activeIndex === index ? 'active' : ''}`} />
// // //                     <p className="service-name">{service.name}</p>
// // //                   </div>
// // //                 ))}
// // //               </div> */}
// // //               <div className="services-grid">
// // //   {services[selectedService].map((service, index) => (
// // //     <div 
// // //       key={index} 
// // //       className={`service-item ${activeIndex === index ? 'active' : ''}`} 
// // //       onClick={() => handleItemClick(index)}
// // //     >
// // //       {service.icon /* No need for img tag here */}
// // //       <p className="service-name">{service.name}</p>
// // //     </div>
// // //   ))}
// // // </div>
// // //               {activeIndex !== null && (
// // //                 <div className="service-description">
// // //                   {/* <p>{services[selectedService][activeIndex].name} - Details about this service will be displayed here.</p>
// // //                    */}
// // //                                   </div>
// // //               )}
// // //               <button className='btn' onClick={handleBackClick}>Back</button>
// // //             </div>
// // //           ) : (
// // //             <>
// // //               <div className="office-info1">
// // //                 <h2 className="title1">EGPMCLLC</h2>
// // //                 <h2 className="title2">EGPM Service Range</h2>
// // //               </div>
// // //               {/* <div className="office">
// // //                 <p>
// // //                   <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}>1. Corporate Governance</Link>
// // //                   <br /><br />
// // //                   <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}>2. Accounting & Finance</Link>
// // //                   <br /><br />
// // //                   <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}>3. HR Consultancy</Link>
// // //                   <br /><br />
// // //                   <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}>4. Trading & Provision of goods</Link>
// // //                 </p>
// // //               </div> */}

// // // {/* <div className="office">
// // //   <p>
// // //     <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}><FaBuilding className="icon1" /> 1. Corporate Governance</Link>
// // //     <br /><br />
// // //     <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}><FaCalculator className="icon1" /> 2. Accounting & Finance</Link>
// // //     <br /><br />
// // //     <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}><FaPeopleCarry className="icon1" /> 3. HR Consultancy</Link>
// // //     <br /><br />
// // //     <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}><FaBoxes className="icon1" /> 4. Trading & Provision of goods</Link>
// // //   </p>
// // // </div> */}
// // // <div className="office">
// // //   {/* <p>
    
// // //     <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}><FaBuilding className="icon1" /> <br/>Corporate Governance</Link>
// // //   </p>
// // //   <p>
    
// // //     <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}><FaCalculator className="icon1" /><br/>Accounting & Finance</Link>
// // //   </p>
// // //   <p>
   
// // //     <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}> <FaPeopleCarry className="icon1" /><br/>HR Consultancy</Link>
// // //   </p>
// // //   <p>
    
// // //     <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}><FaBoxes className="icon1" /><br/>Trading & Provision of goods</Link>
// // //   </p> */}
// // //   <p>
    
// // //     <Link to="#" onClick={() => handleServiceClick("Oil and Gas Services")}><FaBuilding className="icon1" /> <br/>Oil and Gas Services</Link>
// // //   </p>
// // // </div>

// // //             </>
// // //           )}
// // //         </div>
// // //       </>
// // //     );
// // //   }

// // //   export default Services;

// // // import React, { useState } from 'react';
// // // import { FaBuilding, FaCalculator, FaPeopleCarry, FaClipboardCheck, FaFileInvoiceDollar, FaBoxes, FaExchangeAlt, FaChalkboardTeacher, FaHammer, FaListAlt, FaPlane, FaShip } from 'react-icons/fa';
// // // import '../styles/Services.css';

// // // const Services = () => {
// // //   const [selectedService, setSelectedService] = useState(null);

// // //   const services = [
// // //     { name: "Regulatory understanding", icon: <FaBuilding className="icon1" />, description: "We have the capability to operate assets in highly regulated environments." },
// // //     { name: "Efficiency through cost optimization", icon: <FaCalculator className="icon1" />, description: "Our expert engineering teams strive to find new ways to remove cost and complexity, turning to new construction techniques or better use of automation, while remaining focused on delivery quality and certainty." },
// // //     { name: "Experienced people", icon: <FaPeopleCarry className="icon1" />, description: "We firmly believe we are differentiated by the quality of our people. We have capable and experienced people at all levels and they are supported by internal training and    development programmes." },
// // //     { name: "Strong safety record", icon: <FaClipboardCheck className="icon1" />, description: "‘Safe' is one of our core values and on many of our projects we have delivered tens of millions of man-hours without a single safety incident, operating substantially ahead of industry norms." },
// // //     { name: "Risk management", icon: <FaFileInvoiceDollar className="icon1" />, description: "We identify risks from the outset, build up a clear understanding of project complexity and maintain risk management rig our throughout each project." },
// // //     { name: "We work at scale", icon: <FaBoxes className="icon1" />, description: "We can work on some of the biggest oil and gas projects in the world… but also on some of the smallest. No matter what your needs, we can create a team that can help, from a single expert to a team of thousands—and on a standalone basis, or in partnership. We are a truly multinational organisation, with a diverse workforce from all over." },
// // //     { name: "Flexible and responsive organisation", icon: <FaExchangeAlt className="icon1" />,  description: (
// // //       <>
// // //         Wherever we work, our people share a common outlook and the core values which make us unique and inspire our success. We are confident that the combination of our expertise and our values means that we offer something special:
// // //         <br />
// // //         <ul>
// // //           <li>Flexible and responsive organisation with global resource and capability</li>
// // //           <li>Global track record across a diverse range of assets</li>
// // //           <li>Services that span the complete asset life cycle from operations readiness to
// // // normal operation through to late life and removal</li>
// // // <li>Integrated, specialist in-house services providing the ability to identify solutions
// // // and implement them</li>
// // // <li>Proven systems, tools and processes that ensure effective, efficient and
// // // consistent delivery of all our services</li>
// // // <li>Proprietary software allowing for ease of implementation into client systems and
// // // faster realisation of the benefits</li>
// // // <li>A committed and process driven approach to getting it right first time including
// // // meeting the requisite priorities of safety, environment, and reputation.</li>
// // //         </ul>
// // //       </>
// // //     )},
// // //     { name: "Well project management", icon: <FaChalkboardTeacher className="icon1" />, description: "Expertise in managing well projects from inception to completion." },
// // //     { name: "Asset management", icon: <FaHammer className="icon1" />, description: "Comprehensive asset management services to optimize asset performance." },
// // //     { name: "Performance consulting", icon: <FaListAlt className="icon1" />, description: "Consulting services to enhance performance and achieve operational excellence." },
// // //     { name: "Technical consulting", icon: <FaPlane className="icon1" />, description: "Technical consulting services to address complex challenges." },
// // //     { name: "Managed services", icon: <FaShip className="icon1" />, description: "Managed services to support your operations and ensure smooth functioning." }
// // //   ];

// // //   const handleServiceClick = (service) => {
// // //     setSelectedService(service);
// // //   };

// // //   const handleBackClick = () => {
// // //     setSelectedService(null);
// // //   };

// // //   return (
// // //     <>
//       // <div className="contact-container">
//       //   <div className="bg1"></div>
//       //   <div className="overlay"></div>
//       //   <div className="carousel-textt">
//       //     <div className="content-container">
//       //       <div className="content">SERVICES</div>
//       //       <div className="navigation">
//       //         <span>HOME</span> / <span className='contact'>SERVICES</span>
//       //       </div>
//       //     </div>
//       //     <div className="arrow-box"></div>
//       //   </div>
//       // </div>
// // //       <div className='glass1'>
// // //         <div className="office-info1">
// // //           <h2 className="title1">EGPMCLLC</h2>
// // //           <h2 className="title2">EGPM Service Range</h2>
// // //         </div>
// // //         <div className="office">
// // //           <div className="services-grid">
// // //             {services.slice(0, 6).map((service, index) => (
// // //               <div key={index} className="service-item" onClick={() => handleServiceClick(service)}>
// // //                 {service.icon} <br />{service.name}
// // //               </div>
// // //             ))}
// // //           </div>
// // //           <div className="services-grid">
// // //             {services.slice(6).map((service, index) => (
// // //               <div key={index} className="service-item" onClick={() => handleServiceClick(service)}>
// // //                 {service.icon} <br />{service.name}
// // //               </div>
// // //             ))}
// // //           </div>
// // //           {selectedService && (
// // //             <div className="service-description">
// // //               <h3>{selectedService.name}</h3>
// // //               <p>{selectedService.description}</p>
// // //             </div>
// // //           )}
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // }

// // // export default Services;


// // import React, { useState } from 'react';
// // import { FaBuilding, FaCalculator, FaPeopleCarry, FaClipboardCheck, FaFileInvoiceDollar, FaBoxes, FaExchangeAlt, FaChalkboardTeacher, FaHammer, FaListAlt, FaPlane, FaShip } from 'react-icons/fa';
// // import '../styles/Services.css';

// // const Services = () => {
// //   const [selectedService, setSelectedService] = useState(null);

// //   const services = [
// //     { id: 1, name: "Regulatory understanding", icon: <FaBuilding className="icon1" />, description: "We have the capability to operate assets in highly regulated environments." },
// //     { id: 2, name: "Efficiency through cost optimization", icon: <FaCalculator className="icon1" />, description: "Our expert engineering teams strive to find new ways to remove cost and complexity, turning to new construction techniques or better use of automation, while remaining focused on delivery quality and certainty." },
// //     { id: 3, name: "Experienced people", icon: <FaPeopleCarry className="icon1" />, description: "We firmly believe we are differentiated by the quality of our people. We have capable and experienced people at all levels and they are supported by internal training and    development programmes." },
// //     { id: 4, name: "Strong safety record", icon: <FaClipboardCheck className="icon1" />, description: "‘Safe' is one of our core values and on many of our projects we have delivered tens of millions of man-hours without a single safety incident, operating substantially ahead of industry norms." },
// //     { id: 5, name: "Risk management", icon: <FaFileInvoiceDollar className="icon1" />, description: "We identify risks from the outset, build up a clear understanding of project complexity and maintain risk management rig our throughout each project." },
// //     { id: 6, name: "We work at scale", icon: <FaBoxes className="icon1" />, description: "We can work on some of the biggest oil and gas projects in the world… but also on some of the smallest. No matter what your needs, we can create a team that can help, from a single expert to a team of thousands—and on a standalone basis, or in partnership. We are a truly multinational organisation, with a diverse workforce from all over." },
// //     { id: 7, name: "Flexible and responsive organisation", icon: <FaExchangeAlt className="icon1" />,  description: (
// //       <>
// //         Wherever we work, our people share a common outlook and the core values which make us unique and inspire our success. We are confident that the combination of our expertise and our values means that we offer something special:
// //         <br />
// //         <ul>
// //           <li>Flexible and responsive organisation with global resource and capability</li>
// //           <li>Global track record across a diverse range of assets</li>
// //           <li>Services that span the complete asset life cycle from operations readiness to
// // normal operation through to late life and removal</li>
// // <li>Integrated, specialist in-house services providing the ability to identify solutions
// // and implement them</li>
// // <li>Proven systems, tools and processes that ensure effective, efficient and
// // consistent delivery of all our services</li>
// // <li>Proprietary software allowing for ease of implementation into client systems and
// // faster realisation of the benefits</li>
// // <li>A committed and process driven approach to getting it right first time including
// // meeting the requisite priorities of safety, environment, and reputation.</li>
// //         </ul>
// //       </>
// //     )},
// //     { id: 8, name: "Well project management", icon: <FaChalkboardTeacher className="icon1" />, description: "Expertise in managing well projects from inception to completion." },
// //     { id: 9, name: "Asset management", icon: <FaHammer className="icon1" />, description: "Comprehensive asset management services to optimize asset performance." },
// //     { id: 10, name: "Performance consulting", icon: <FaListAlt className="icon1" />, description: "Consulting services to enhance performance and achieve operational excellence." },
// //     { id: 11, name: "Technical consulting", icon: <FaPlane className="icon1" />, description: "Technical consulting services to address complex challenges." },
// //     { id: 12, name: "Managed services", icon: <FaShip className="icon1" />, description: "Managed services to support your operations and ensure smooth functioning." }
// //   ];

// //   const handleServiceClick = (service) => {
// //     setSelectedService(service);
// //   };

// //   return (
// //     <>
// //       <div className="contact-container">
// //         <div className="bg1"></div>
// //         <div className="overlay"></div>
// //         <div className="carousel-textt">
// //           <div className="content-container">
// //             <div className="content">SERVICES</div>
// //             <div className="navigation">
// //               <span>HOME</span> / <span className='contact'>SERVICES</span>
// //             </div>
// //           </div>
// //           <div className="arrow-box"></div>
// //         </div>
// //       </div>
// //       <div className='glass1'>
// //         <div className="office-info1">
// //           <h2 className="title1">EGPMCLLC</h2>
// //           <h2 className="title2">EGPM Service Range</h2>
// //         </div>
// //         <div className="office">
// //           <div className="services-grid">
// //             {services.map((service) => (
// //               <div
// //                 key={service.id}
// //                 className={`service-item ${selectedService === service ? 'active' : ''}`}
// //                 onClick={() => handleServiceClick(service)}
// //               >
// //                 {service.icon} <br />{service.name}
// //               </div>
// //             ))}
// //           </div>
// //           {selectedService && (
// //             <div className="service-description">
// //               <h3>{selectedService.name}</h3>
// //               <p>{selectedService.description}</p>
// //             </div>
// //           )}
// //         </div>
// //       </div>
// //     </>
// //   );
// // }

// // export default Services;

















// import React, { useState } from 'react';
// import {
//   FaBuilding,
//   FaCalculator,
//   FaPeopleCarry,
//   FaClipboardCheck,
//   FaFileInvoiceDollar,
//   FaBoxes,
//   FaExchangeAlt,
//   FaChalkboardTeacher,
//   FaHammer,
//   FaListAlt,
//   FaPlane,
//   FaShip,
//   FaLock
// } from 'react-icons/fa';
// import '../styles/Services.css';

// const Services = () => {
//   const [selectedService, setSelectedService] = useState(null);
  

//   const services = [
//     { id: 1, name: "Regulatory understanding", icon: <FaBuilding className="icon1" />, description: "We have the capability to operate assets in highly regulated environments." },
//     { id: 2, name: "Efficiency through cost optimization", icon: <FaCalculator className="icon1" />, description: "Our expert engineering teams strive to find new ways to remove cost and complexity, turning to new construction techniques or better use of automation, while remaining focused on delivery quality and certainty." },
//     { id: 3, name: "Experienced people", icon: <FaPeopleCarry className="icon1" />, description: "We firmly believe we are differentiated by the quality of our people. We have capable and experienced people at all levels and they are supported by internal training and development programmes." },
//     { id: 4, name: "Strong safety record", icon: <FaClipboardCheck className="icon1" />, description: "‘Safe' is one of our core values and on many of our projects we have delivered tens of millions of man-hours without a single safety incident, operating substantially ahead of industry norms." },
//     { id: 5, name: "Risk management", icon: <FaFileInvoiceDollar className="icon1" />, description: "We identify risks from the outset, build up a clear understanding of project complexity and maintain risk management rig our throughout each project." },
//     { id: 6, name: "We work at scale", icon: <FaBoxes className="icon1" />, description: "We can work on some of the biggest oil and gas projects in the world… but also on some of the smallest. No matter what your needs, we can create a team that can help, from a single expert to a team of thousands—and on a standalone basis, or in partnership. We are a truly multinational organisation, with a diverse workforce from all over." },
//     { id: 7, name: "Flexible and responsive organisation", icon: <FaExchangeAlt className="icon1" />,  description: (
//       <>
//         Wherever we work, our people share a common outlook and the core values which make us unique and inspire our success. We are confident that the combination of our expertise and our values means that we offer something special:
//         <br />
//         <ul>
//           <li>Flexible and responsive organisation with global resource and capability</li>
//           <li>Global track record across a diverse range of assets</li>
//           <li>Services that span the complete asset life cycle from operations readiness to normal operation through to late life and removal</li>
//           <li>Integrated, specialist in-house services providing the ability to identify solutions and implement them</li>
//           <li>Proven systems, tools and processes that ensure effective, efficient and consistent delivery of all our services</li>
//           <li>Proprietary software allowing for ease of implementation into client systems and faster realisation of the benefits</li>
//           <li>A committed and process driven approach to getting it right first time including meeting the requisite priorities of safety, environment, and reputation.</li>
//         </ul>
//       </>
//     )},
//     { id: 8, name: "Well project management", icon: <FaChalkboardTeacher className="icon1" />, description: (
//       <>
//        We provide experts with the skills to cover anything from small scopes to acting as
// lead contractor, coordinating service providers and delivering large scale projects.
// <br />
// Our well project management services can be tailored to complement your existing in
// house capability. Our selection of experienced and operationally active technical
// professionals gives us the ability to quickly scale up or down for your requirements.
// <br />
// Our services include:
//         <br />
//         <ul>
//           <li>Well design and planning</li>
//           <li>Well operator</li>
//           <li>Operations management</li>
//           <li>Regulatory approvals</li>
//           <li>Contracting</li>
//           <li>Concept and detailed design</li>
//           <li>Operational execution</li>
//           <li>HSEQ management</li>
//           <li> Well integrity</li>
//           <li>      Well accounting and cost tracking</li>
// <li>Project close-out</li>
//           </ul>
//       </>
//     ) },
//     { id: 9, name: "Asset management", icon: <FaHammer className="icon1" />,description: (
//       <>
//        Our asset management consulting services help our clients to transform their
// organisations, meet international regulations, improve asset performance and
// maximise return on their asset-based investments.<br />
// Our clients may be facing a range of asset performance challenges, and our suite of
// services, built on our extensive experience, means that we are ideally placed to work
// with our clients to:<br />
//         <ul>
//         <li>Define the optimum solution to their challenges</li>
//         <li>Develop programmes to achieve that result</li>
//         <li>Implement innovative and cost-efficient solutions to achieve optimised and
// compliant performance</li>
// <li>As a result, our asset management consulting services can provide significant value to our clients, including:</li>  
// <li>Improved safety and integrity</li>
// <li>Increased reliability and availability</li>
// <li>Reduced cost</li>
// <li>Assured compliance</li>
// <li> Management of risk</li>

//           </ul>
//       </>
//     ) },
//     { id: 10, name: "Performance consulting", icon: <FaListAlt className="icon1" />, description: "Physical asset benchmarking and opportunity identification and implementation.    " },
//     { id: 11, name: "Technical consulting", icon: <FaPlane className="icon1" />, description: "Asset technical support, development of systems, processes, programmes and competency solutions." },
//     { id: 12, name: "Managed services", icon: <FaShip className="icon1" />, description: "Complete asset management service—manpower provision, functional support and supervision. We also offer full facilities management support and production enhancement services for both onshore and offshore assets." },
//     { id: 13, name: "Proprietary Software", icon: <FaLock className="icon1" />, description: "We develop proprietary asset management software solutions that ensure effective, efficient and consistent delivery of all our services." }
//   ];

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
    
//   };

//   return (
//     <>
//       <div className="contact-container">
//         <div className="bg1"></div>
//         <div className="overlay"></div>
//         <div className="carousel-textt">
//           <div className="content-container">
//             <div className="content">SERVICES</div>
//             <div className="navigation">
//               <span>HOME</span> / <span className='contact'>SERVICES</span>
//             </div>
//           </div>
//           <div className="arrow-box"></div>
//         </div>
//       </div>
//       <div className="glass1">
//         <div className="office-info1">
//           <h2 className="title1">EGPMCLLC</h2>
//           <h2 className="title2">EGPM Service Range</h2>
//         </div>
//         <div className="office">
//           <div className="services-grid">
//             {services.map((service) => (
//               <div
//                 key={service.id}
//                 className={`service-item ${selectedService === service ? 'active' : ''}`}
//                 onClick={() => handleServiceClick(service)}
//               >
//                 {service.icon} <br />{service.name}
//               </div>
//             ))}
//           </div>
//           {selectedService && (
//             <div className="service-description">
//               <h3>{selectedService.name}</h3>
//               <p>{selectedService.description}</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Services;



import React, { useState } from 'react';
import {
  FaBuilding,
  FaCalculator,
  FaPeopleCarry,
  FaClipboardCheck,
  FaFileInvoiceDollar,
  FaBoxes,
  FaExchangeAlt,
  FaChalkboardTeacher,
  FaHammer,
  FaListAlt,
  FaPlane,
  FaShip,
  FaLock,
  FaTools
} from 'react-icons/fa';
import '../styles/Services.css';

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    { id: 1, name: "Regulatory understanding", icon: <FaBuilding className="icon1" />, description: "We have the capability to operate assets in highly regulated environments." },
    { id: 2, name: "Efficiency through cost optimization", icon: <FaCalculator className="icon1" />, description: "Our expert engineering teams strive to find new ways to remove cost and complexity, turning to new construction techniques or better use of automation, while remaining focused on delivery quality and certainty." },
    { id: 3, name: "Experienced people", icon: <FaPeopleCarry className="icon1" />, description: "We firmly believe we are differentiated by the quality of our people. We have capable and experienced people at all levels and they are supported by internal training and development programmes." },
    { id: 4, name: "Strong safety record", icon: <FaClipboardCheck className="icon1" />, description: "‘Safe' is one of our core values and on many of our projects we have delivered tens of millions of man-hours without a single safety incident, operating substantially ahead of industry norms." },
    { id: 5, name: "Risk management", icon: <FaFileInvoiceDollar className="icon1" />, description: "We identify risks from the outset, build up a clear understanding of project complexity and maintain risk management rig our throughout each project." },
    { id: 6, name: "We work at scale", icon: <FaBoxes className="icon1" />, description: "We can work on some of the biggest oil and gas projects in the world… but also on some of the smallest. No matter what your needs, we can create a team that can help, from a single expert to a team of thousands—and on a standalone basis, or in partnership. We are a truly multinational organisation, with a diverse workforce from all over." },
    { id: 7, name: "Flexible and responsive organisation", icon: <FaExchangeAlt className="icon1" />,  description: (
      <>
        Wherever we work, our people share a common outlook and the core values which make us unique and inspire our success. We are confident that the combination of our expertise and our values means that we offer something special:
        <br />
        <ul>
          <li>Flexible and responsive organisation with global resource and capability</li>
          <li>Global track record across a diverse range of assets</li>
          <li>Services that span the complete asset life cycle from operations readiness to normal operation through to late life and removal</li>
          <li>Integrated, specialist in-house services providing the ability to identify solutions and implement them</li>
          <li>Proven systems, tools and processes that ensure effective, efficient and consistent delivery of all our services</li>
          <li>Proprietary software allowing for ease of implementation into client systems and faster realisation of the benefits</li>
          <li>A committed and process driven approach to getting it right first time including meeting the requisite priorities of safety, environment, and reputation.</li>
        </ul>
      </>
    )},
    { id: 8, name: "Well project management", icon: <FaChalkboardTeacher className="icon1" />, description: (
      <>
       We provide experts with the skills to cover anything from small scopes to acting as
lead contractor, coordinating service providers and delivering large scale projects.
<br />
Our well project management services can be tailored to complement your existing in
house capability. Our selection of experienced and operationally active technical
professionals gives us the ability to quickly scale up or down for your requirements.
<br />
Our services include:
        <br />
        <ul>
          <li>Well design and planning</li>
          <li>Well operator</li>
          <li>Operations management</li>
          <li>Regulatory approvals</li>
          <li>Contracting</li>
          <li>Concept and detailed design</li>
          <li>Operational execution</li>
          <li>HSEQ management</li>
          <li> Well integrity</li>
          <li>      Well accounting and cost tracking</li>
<li>Project close-out</li>
          </ul>
      </>
    ) },
    { id: 9, name: "Asset management", icon: <FaHammer className="icon1" />,description: (
      <>
       Our asset management consulting services help our clients to transform their
organisations, meet international regulations, improve asset performance and
maximise return on their asset-based investments.<br />
Our clients may be facing a range of asset performance challenges, and our suite of
services, built on our extensive experience, means that we are ideally placed to work
with our clients to:<br />
        <ul>
        <li>Define the optimum solution to their challenges</li>
        <li>Develop programmes to achieve that result</li>
        <li>Implement innovative and cost-efficient solutions to achieve optimised and
compliant performance</li>
<li>As a result, our asset management consulting services can provide significant value to our clients, including:</li>  
<li>Improved safety and integrity</li>
<li>Increased reliability and availability</li>
<li>Reduced cost</li>
<li>Assured compliance</li>
<li> Management of risk</li>

          </ul>
      </>
    ) },
    { id: 10, name: "Performance consulting", icon: <FaListAlt className="icon1" />, description: "Physical asset benchmarking and opportunity identification and implementation.    " },
    { id: 11, name: "Technical consulting", icon: <FaPlane className="icon1" />, description: "Asset technical support, development of systems, processes, programmes and competency solutions." },
    { id: 12, name: "Managed services", icon: <FaShip className="icon1" />, description: "Complete asset management service—manpower provision, functional support and supervision. We also offer full facilities management support and production enhancement services for both onshore and offshore assets." },
    { id: 13, name: "Proprietary Software", icon: <FaLock className="icon1" />, description: "We develop proprietary asset management software solutions that ensure effective, efficient and consistent delivery of all our services." },
    { id: 14, name: "Operations and maintenance", icon: <FaTools className="icon1" />, description: (
      <>At EGPM, we know what it takes to increase
    production safely and maximise efficiency from oil
    and gas operations.<br />
    We can deliver fit-for-purpose operations, maintenance and asset management
    solutions. We customise our support in line with your operating philosophies and asset
    strategy to provide value adding outcomes.<br />
    Our work has increased production, optimised efficiency, reduced OPEX and lowered
    lifting costs, which has resulted in improved economic viability and extended field life.<br />
    Today, we’re working with our global digital technology partners to apply the latest
    digital technologies to our operations. From predictive analytics, to wearable
    technologies that better connect our on and offshore teams, we’re seeking new ways
    to deliver ultra-efficient asset support. </>
    ) }
 
  ];

  const handleServiceClick = (service) => {
    setSelectedService(service.id === selectedService?.id ? null : service);
  };

  return (
    <>
      <div className="contact-container">
        <div className="bg1"></div>
        <div className="overlay"></div>
        <div className="carousel-textt">
          <div className="content-container">
            <div className="content">SERVICES</div>
            <div className="navigation">
              <span>HOME</span> / <span className='contact'>SERVICES</span>
            </div>
          </div>
          <div className="arrow-box"></div>
        </div>
      </div>
      <div className="glass1">
        <div className="office-info1">
          <h2 className="title1">EGPMCLLC</h2>
          <h2 className="title2">EGPM Service Range</h2>
        </div>
        <div className="office">
          <div className="services-grid">
            {services.map((service) => (
              <div
                key={service.id}
                className={`service-item ${selectedService?.id === service.id ? 'active' : ''}`}
                onClick={() => handleServiceClick(service)}
              >
                {service.icon} <br />{service.name}
              </div>
            ))}
          </div>
          {selectedService && (
            <div className="service-description">
              <h3>{selectedService.name}</h3>
              <p>{selectedService.description}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Services;
