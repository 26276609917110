
import React from 'react';
import { FaMapMarkerAlt, FaLinkedin, FaFacebook,FaTwitter, FaBuilding } from 'react-icons/fa';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-text">All rights reserved</div>
                <div className="footer-icons">
                    <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">
                        <FaMapMarkerAlt className="icon" />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className="icon" />
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="icon" />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="icon" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
