import React from 'react';
import { Link } from 'react-router-dom'; 
import '../styles/Products.css'; // Import your CSS file for styling
import img1 from '../assets/4.jpg';
import img2 from '../assets/ffe.png';
import img3 from '../assets/2.jpg';
import img4 from '../assets/wfilter.png';

const Products = () => {
  return (
    <>
      <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="pd1"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">PRODUCTS</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>PRODUCTS</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

      <div className="product-container">
        <Link to="/oil" className="product-link">
          <div className="product-item">
            <img src={img1} alt="Oil & Gas" className="product-image" />
            <div className="product-name">Oil & Gas</div>
          </div>
        </Link>
        <Link to="/hse" className="product-link">
          <div className="product-item">
            <img src={img2} alt="Petrochemicals" className="product-image" />
            <div className="product-name">FFE/ HSE</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img3} alt="Pumps, Engines & Spare Parts" className="product-image" />
            <div className="product-name">Pumps, Engines & Spare Parts</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img1} alt="Heavy Equipment & Machinery Spare Parts" className="product-image" />
            <div className="product-name">Heavy Equipment & Machinery Spare Parts</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img4} alt="Filtration" className="product-image" />
            <div className="product-name">Filtration</div>
          </div>
        </Link>
       
      </div>
    </>
  );
}

export default Products;
