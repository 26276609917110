// // import React from 'react'
// // import '../styles/About.css';

// // const Careers = () => {
// //   return <>
// //   <div className="contact-container">
// //     <div className="background-image1"></div>
// //     <div className="overlay"></div>
  
// //     <div className="content">ABOUT</div>
// //       <div className="navigation">
      
// //         <span>HOME</span> / <span className='contact'>ABOUT</span>
      
// //     </div>
// //     </div>
 
// // <div className='glass'>
// //   <div className="office-info">
// //         <h2 style={{ marginLeft: '730px' }}>OGMCLLC</h2>
// //         <h2 style={{ marginLeft: '550px' }}>Our Management has Decades of Experience
// // </h2>
// //  </div>
// //         <div className="office">
          
          
// //           <p>From its origin in 2006, OGMCLLC has been part of Al Hijab International and later in 2020 OGMCLLC emerged as a separate entity and new brand and as a global service provider specializing in the Trainings, Management Consulting, Payroll and flexible deployment of specialists to the international oil & gas, Power & Energy including renewable energy, engineering and construction, automotive, manufacturing and other related industries.

// // Today, Octane is a strong global brand. Our annual turnover is USD 1.5 million. Octane International Energy Co. Ltd has a plan to enlist with Dubai Stock Exchange by 2025.

// // At OGMC LLC, we are committed to deliver the superlative solutions to our worthy clients with assurance of quality and their specific needs. We have been working in the field of HR Services, including Manpower Recruitment, Employee Outsourcing, Payroll and Training & Development with global integration and market mix.

// // Your inquiry and response on this subject will be highly appreciated and will be addressed with great care.
// // </p>
          
// //         </div>
// //         </div>
       
     
  
// //   </>;
// // }

// // export default Careers


// import React from 'react';
// import '../styles/About.css';

// const Careers = () => {
//   return (
//     <>
//      <div className="contact-container">
//       {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
//         <div className="bg2"></div>
//         <div className="overlay"></div>
        
//       <div className="carousel-textt">
//   <div class="content-container">
//     <div class="content">ABOUT</div>
//     <div class="navigation">
//       <span>HOME</span> / <span class='contact'>ABOUT</span>
//     </div>
//   </div>
//   <div class="arrow-box"></div>
// </div>

       
//       </div>
//       <div className='glass'>
//         <div className="office-info12">
//           <h2 className="title1">EGPMCLLC</h2>
//           <h2 className="title2">Our Management has Decades of Experience</h2>
//         </div>
//         {/* <div className="office">
//           <p>
//             From its origin in 2006, EGPMCLLC has been part of Al Hijab International and later in 2020 EGPMCLLC emerged as a separate entity and new brand and as a global service provider specializing in the Trainings, Management Consulting, Payroll and flexible deployment of specialists to the international oil & gas, Power & Energy including renewable energy, engineering and construction, automotive, manufacturing and other related industries.
//             <br /><br />
//             Today, Octane is a strong global brand. Our annual turnover is USD 1.5 million. Octane International Energy Co. Ltd has a plan to enlist with Dubai Stock Exchange by 2025.
//             <br /><br />
//             At EGPMC LLC, we are committed to deliver the superlative solutions to our worthy clients with assurance of quality and their specific needs. We have been working in the field of HR Services, including Manpower Recruitment, Employee Outsourcing, Payroll and Training & Development with global integration and market mix.
//             <br /><br />
//             Your inquiry and response on this subject will be highly appreciated and will be addressed with great care.
//           </p>
//         </div> */}
//       </div>
//     </>
//   );
// }

// export default Careers;


import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <>
      <div className="contact-container">
        <div className="bg2"></div>
        <div className="overlay"></div>
        <div className="carousel-textt">
          <div className="content-container">
            <div className="content">ABOUT</div>
            <div className="navigation">
              <span>HOME</span> / <span className='contact'>ABOUT</span>
            </div>
          </div>
          <div className="arrow-box"></div>
        </div>
      </div>
      <div className='glass'>
        <div className="office-info12">
          <h2 className="title1">EGPMCLLC</h2>
          <h2 className="title2">Our Management has Decades of Experience</h2>
        </div>
        <div className="office">
          <p>
            Energic Global Project Management (EGPM) has a great track record of delivering high-profile, high-value projects in vastly different locations around the world. Much of this success is down to the way we manage projects.
            <br /><br />
            Our priorities are safety and client satisfaction. We take enormous pride in making sure our people operate in a safe environment, and our projects are completed successfully. From client presentations to writing proposals, managing projects, and developing capability, excellent project management is the thread that runs through the entire business.
            <br /><br />
            Working across the international oil and gas industry, we help our clients unlock the full value of their energy assets. We design, build, operate, and maintain oil and gas facilities. Our service capability spans the oil and gas asset life cycle, from conceptual greenfield developments through to brownfield modifications, late life, and decommissioning.
            <br /><br />
            Our strength lies in the depth of our operating knowledge and the breadth of our service capability. As a people business, we have a problem-solving culture and a clear focus on delivery which is evident in every project and contract we undertake.
            <br /><br />
            By thinking differently about service delivery, we align our approach to meet your operating strategies and project objectives—our approach to delivery is flexible and tailored. Where possible we integrate our services to unlock value across the asset life cycle. And our approach is underpinned by a range of innovative commercial models which enable us to respond flexibly to your needs.
            <br /><br />
            We combine the best of all we do to provide solutions that minimize risk, enhance integrity, and unlock value. We offer regulatory understanding, efficiency through cost optimization, experienced people, strong safety record, risk management, and scalability in project sizes.
            <br /><br />
            EGPMCLLC is located at Office No. 1606, Tamani Arts Office Business Bay, Dubai, U.A.E.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
