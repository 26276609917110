import React from 'react';
import { Link } from 'react-router-dom'; 
import '../styles/Products.css'; // Import your CSS file for styling
import img1 from '../assets/mining.jpg';
import img2 from '../assets/oil.jpg';
import img3 from '../assets/cst.jpg';
import img4 from '../assets/govt.jpg';
import img5 from '../assets/part.jpg';
import img6 from '../assets/fin.jpg';
import img7 from '../assets/cons.jpg';
import img8 from '../assets/social.jpg';
import img9 from '../assets/transport.jpg';
import img10 from '../assets/energy.jpg';
import img11 from '../assets/health.jpg';
import img12 from '../assets/travel.jpg';

const Market = () => {
  return (
    <>
      <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="bg2"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">MARKET</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>MARKET</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

      <div className="product-container">
        <Link to="/oil" className="product-link">
          <div className="product-item">
            <img src={img1} alt="Oil & Gas" className="product-image" />
            <div className="product-name">Mining & Industrial</div>
          </div>
        </Link>
       
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img2} alt="Pumps, Engines & Spare Parts" className="product-image" />
            <div className="product-name">Oil & Gas Drilling</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img3} alt="Heavy Equipment & Machinery Spare Parts" className="product-image" />
            <div className="product-name">Real State & Construction</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img4} alt="Filtration" className="product-image" />
            <div className="product-name">Government</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img5} alt="Batteries" className="product-image" />
            <div className="product-name">Public, Private Partnerships</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img6} alt="Batteries" className="product-image" />
            <div className="product-name">Financial Services</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img7} alt="Batteries" className="product-image" />
            <div className="product-name">Consumer</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img8} alt="Batteries" className="product-image" />
            <div className="product-name">Technology, Media & Communication</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img9} alt="Batteries" className="product-image" />
            <div className="product-name">Transport & Logistics</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img10} alt="Batteries" className="product-image" />
            <div className="product-name">Energy, Resources & Utilities</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img11} alt="Batteries" className="product-image" />
            <div className="product-name">Hospitality, Healthcare & Sports</div>
          </div>
        </Link>
        <Link to="#" className="product-link">
          <div className="product-item">
            <img src={img12} alt="Petrochemicals" className="product-image" />
            <div className="product-name">Travel & Tourism</div>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Market;
