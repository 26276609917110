// import React, { useState } from 'react';
// import '../styles/HSE.css';

// // Import images for HSE/PPE products
// import img1 from '../assets/fe.png';
// import img2 from '../assets/haa.png';
// import img3 from '../assets/firec.png';
// import img4 from '../assets/vre.png';
// import img5 from '../assets/fireh.png';
// import img6 from '../assets/fireb.png';
// import img7 from '../assets/firepump.png';
// import img8 from '../assets/pumph.png';
// import img9 from '../assets/pumpset.png';
// import img10 from '../assets/advpump.png';

// const HSE = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const handleTabClick = (index) => {
//     setActiveTab(index);
//   };
//   const productImages = {
//     'Fire Extinguishers': img1,
//     'Fire Hoses & Accessories': img2,
//     'Fire Cabinets': img3,
//     'Valves and Riser Equipment': img4,
//     'Fire Hydrants': img5,
//     'Fire Blankets': img6,
//     'Fire Pump Products': img7,
//     'Pump Hose Unit': img8,
//     'Industrial Packaged Pumpset': img9,
//     'Advanced Fire Pump Solutions': img10,
//     'Foam Concentrate': img1,
//     'Foam Equipments': img1,
//     'Gas Based System': img1,
//     'Fire Ducts & Dampers': img4,
//     'Natural Smoke Vents': img5,
//     'Fire & Smoke Curtains': img6,
//     'Starter Panels': img7,
//     'Smoke Control Stations': img8,
//     'Smoke, CO & Nox Detectors': img9,
//     'Electrostatic Precipitator Solutions': img2,
//     'Firefighter Equipment': img1,
//     'Industrial': img2,
//     'Shield': img3,
//     'Triga': img4,
//     'Security Systems': img5,
//     'ICT (Information Communication Technology)': img6,
//     'Audio Visuals': img7,
//     'Special Systems': img8,
//     'Fire Trucks & Special Vehicles': img9,
//     'Ambulances & Special Vehicles': img3,
//     'Escape Route Signs': img1,
//     'Fire Fighting Equipment Signs': img2,
//     'Warning Signs': img3,
//     'Mandatory Signs': img4,
//     'Prohibition Signs': img5,
//     'Low Location Lighting': img6,
//     'Traffic Signs': img7,
//     'Tunnel Signs': img8,
//     'Building Signs': img9
//   };
  
//   const renderProduct = (product) => {
//     const productImage = productImages[product] || img1;
//     return (
//       <div key={product} className="product">
//         <img src={productImage} alt={product} />
//         <p>{product}</p>
//       </div>
//     );
//   };

//   const productsByTab = [
//     ['Fire Extinguishers', 'Fire Hoses & Accessories', 'Fire Cabinets', 'Valves and Riser Equipment', 'Fire Hydrants', 'Fire Blankets'],
//     ['Fire Pump Products', 'Pump Hose Unit', 'Industrial Packaged Pumpset', 'Advanced Fire Pump Solutions'],
//     ['Foam Concentrate', 'Foam Equipments'],
//     ['Gas Based System'],
//     ['Fire Ducts & Dampers', 'Natural Smoke Vents', 'Fire & Smoke Curtains', 'Starter Panels', 'Smoke Control Stations', 'Smoke, CO & Nox Detectors', 'Electrostatic Precipitator Solutions'],
//     ['Firefighter Equipment', 'Industrial'],
//     ['Shield', 'Triga'],
//     ['Security Systems', 'ICT (Information Communication Technology)', 'Audio Visuals', 'Special Systems'],
//     ['Fire Trucks & Special Vehicles', 'Ambulances & Special Vehicles'],
//     ['Escape Route Signs', 'Fire Fighting Equipment Signs', 'Warning Signs', 'Mandatory Signs', 'Prohibition Signs', 'Low Location Lighting', 'Traffic Signs', 'Tunnel Signs', 'Building Signs'],
//   ].map(tab => tab || []);
 

//   return (
//     <>
//       <div className="contact-container">
//         {/* Background and overlay */}
//       </div>

//       <div className="products-info">
//         <a href="/products" className="back-button">BACK</a>
//         <h2>HSE/PPE Services</h2>
//         {/* <div className="tabs-wrapper">
//           <div className="tabs-container">
//             {['All Services', 'Fire Fighting', 'Fire Pumps', 'Foam Equipment', 'Fire Protection', 'Smoke Management', 'Safety & Rescue', 'Extra Low Voltage', 'ELV Integrated System', 'Trucks & Vehicles', 'Safety Signs'].map((tabName, index) => (
//               <div
//                 key={index}
//                 className={`tab ${index === activeTab ? 'active' : ''}`}
//                 onClick={() => handleTabClick(index)}
//               >
//                 {tabName}
//               </div>
//             ))}
//           </div>
//         </div> */}
//         <div className="tabs-wrapper">
//   <div className="tabs-container">
//     {['Fire Fighting', 'Fire Pumps', 'Foam Equipment', 'Fire Protection'].map((tabName, index) => (
//       <div
//         key={index}
//         className={`tab ${index === activeTab ? 'active' : ''}`}
//         onClick={() => handleTabClick(index)}
//       >
//         {tabName}
//       </div>
//     ))}
//   </div>
//   <div className="tabs-container">
//     {['Smoke Management', 'Safety & Rescue', 'Extra Low Voltage', 'ELV Integrated System', 'Trucks & Vehicles', 'Safety Signs'].map((tabName, index) => (
//       <div
//         key={index + 5} // Adding a unique key to avoid conflicts
//         className={`tab ${index + 5 === activeTab ? 'active' : ''}`}
//         onClick={() => handleTabClick(index + 5)}
//       >
//         {tabName}
//       </div>
//     ))}
//   </div>
// </div>

//         <div className="tab-content">
//           {productsByTab[activeTab].map((product) => renderProduct(product))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default HSE;

import React, { useState } from 'react';
import '../styles/HSE.css';

// Import images for HSE/PPE products
import img1 from '../assets/fe.png';
import img41 from '../assets/fhr.png';
import img2 from '../assets/haa.png';
import img3 from '../assets/firec.png';
import img4 from '../assets/vre.png';
import img5 from '../assets/fireh.png';
import img6 from '../assets/fireb.png';
import img7 from '../assets/firepump.png';
import img8 from '../assets/pumph.png';
import img9 from '../assets/pumpset.png';
import img43 from '../assets/firedoor.png';
import img44 from '../assets/galzing.png';
import img45 from '../assets/firecurt.png';
import img46 from '../assets/blastd.png';
import img47 from '../assets/secdoor.png';
import img48 from '../assets/rolls.png';
import img49 from '../assets/accessd.png';
import img10 from '../assets/advpump.png';
import img11 from '../assets/foamc.png';
import img12 from '../assets/foame.png';
import img13 from '../assets/fireps.png';
import img14 from '../assets/fanss.png';
import img15 from '../assets/ducts.png';
import img16 from '../assets/natsmoke.png';
import img17 from '../assets/firesmoke.png';
import img18 from '../assets/startp.png';
import img19 from '../assets/catm.png';
import img20 from '../assets/smoke.png';
import img21 from '../assets/precip.png';
import img42 from '../assets/Solutions.jpg';
import img22 from '../assets/ffe.png';
import img23 from '../assets/indus.jpg';
import img24 from '../assets/shield.png';
import img25 from '../assets/triga.png';
import img26 from '../assets/securitysys.png';
import img27 from '../assets/ict.png';
import img28 from '../assets/audiov.jpg';
import img29 from '../assets/specsys.png';
import img30 from '../assets/truck.png';
import img31 from '../assets/amb.png';
import img32 from '../assets/escaper.png';
import img33 from '../assets/firefight.png';
import img34 from '../assets/warning.png';
import img35 from '../assets/mandot.png';
import img36 from '../assets/prohib.png';
import img37 from '../assets/lowl.png';
import img38 from '../assets/traffic.png';
import img39 from '../assets/tunnel.png';
import img40 from '../assets/building.png';


const HSE = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const productImages = {
    'Fire Extinguishers': img1,
    'Fire Hose Reel & Accessories': img41,
    'Fire Hoses & Accessories': img2,
    'Fire Cabinets': img3,
    'Valves and Riser Equipment': img4,
    'Fire Hydrants': img5,
    'Fire Blankets': img6,
    'Fire Pump Products': img7,
    'Pump Hose Unit': img8,
    'Industrial Packaged Pumpset': img9,
    'Advanced Fire Pump Solutions': img10,
    'Fire Rated Doors': img43,
    'Glasing System': img44,
    'Fire & Smoke Curtain': img45,
    'Blast Doors': img46,
    'Security Doors': img47,
    'Rolling Shutters': img48,
    'Access Doors': img49,
    'Foam Concentrate': img11,
    'Foam Equipments': img12,
    'Gas Based System': img13,
    'Fans': img14,
    'Fire Ducts & Dampers': img15,
    'Natural Smoke Vents': img16,
    'Fire & Smoke Curtains': img17,
    'Starter Panels': img18,
    'Smoke Control Stations': img19,
    'Smoke, CO & Nox Detectors': img20,
    'Electrostatic Precipitator Solutions': img21,
    'Solutions': img42,
    'Firefighter Equipment': img22,
    'Industrial': img23,
    'Shield': img24,
    'Triga': img25,
    'Security Systems': img26,
    'ICT (Information Communication Technology)': img27,
    'Audio Visuals': img28,
    'Special Systems': img29,
    'Fire Trucks & Special Vehicles': img30,
    'Ambulances & Special Vehicles': img31,
    'Escape Route Signs': img32,
    'Fire Fighting Equipment Signs': img33,
    'Warning Signs': img34,
    'Mandatory Signs': img35,
    'Prohibition Signs': img36,
    'Low Location Lighting': img37,
    'Traffic Signs': img38,
    'Tunnel Signs': img39,
    'Building Signs': img40
  };

  const renderProduct = (product) => {
    const productImage = productImages[product] || img1;
    return (
      <div key={product} className="product">
        <img src={productImage} alt={product} />
        <p>{product}</p>
      </div>
    );
  };

  const productsByTab = [
    ['Fire Extinguishers','Fire Hose Reel & Accessories', 'Fire Hoses & Accessories', 'Fire Cabinets', 'Valves and Riser Equipment', 'Fire Hydrants', 'Fire Blankets'],
    ['Fire Pump Products', 'Pump Hose Unit', 'Industrial Packaged Pumpset', 'Advanced Fire Pump Solutions'],
    ['Fire Rated Doors','Glasing System','Fire & Smoke Curtain','Blast Doors','Security Doors','Rolling Shutters','Access Doors'],
    ['Foam Concentrate', 'Foam Equipments'],
    ['Gas Based System'],
    ['Fans','Fire Ducts & Dampers', 'Natural Smoke Vents', 'Fire & Smoke Curtains', 'Starter Panels', 'Smoke Control Stations', 'Smoke, CO & Nox Detectors', 'Electrostatic Precipitator Solutions','Solutions'],
    ['Firefighter Equipment', 'Industrial'],
    ['Shield', 'Triga'],
    ['Security Systems', 'ICT (Information Communication Technology)', 'Audio Visuals', 'Special Systems'],
    ['Fire Trucks & Special Vehicles', 'Ambulances & Special Vehicles'],
    ['Escape Route Signs', 'Fire Fighting Equipment Signs', 'Warning Signs', 'Mandatory Signs', 'Prohibition Signs', 'Low Location Lighting', 'Traffic Signs', 'Tunnel Signs', 'Building Signs'],
  ].map(tab => tab || []);

  return (
    <>
      <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="hse1"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">PRODUCTS</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>PRODUCTS</span>/ <span class='contact'>HSE/ PPE</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

      <div className="products-info">
        <a href="/products" className="back-button">BACK</a>
        <h2>HSE/PPE Services</h2>
        <div className="tabs-wrapper">
          <div className="tabs-container">
            {['Fire Fighting', 'Fire Pumps','Fire Doors', 'Foam Equipment', 'Fire Protection'].map((tabName, index) => (
              <div
                key={index}
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tabName}
              </div>
            ))}
          </div>
          <div className="tabs-container">
            {['Smoke Management', 'Safety & Rescue', 'Extra Low Voltage', 'ELV Integrated System', 'Trucks & Vehicles', 'Safety Signs'].map((tabName, index) => (
              <div
                key={index + 5} // Adding a unique key to avoid conflicts
                className={`tab ${index + 5 === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index + 5)}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>

        <div className="tab-content">
          {/* Add a check to ensure activeTab is within the range of available tabs */}
          {Array.isArray(productsByTab[activeTab]) && productsByTab[activeTab].map((product) => renderProduct(product))}
        </div>
      </div>
    </>
  );
}

export default HSE;
