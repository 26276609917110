import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../styles/Contact.css'; // Import your CSS file for styling

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert('Please complete the captcha');
      return;
    }

    // Replace with your EmailJS service ID, template ID, and user ID
    const serviceID = 'YOUR_SERVICE_ID';
    const templateID = 'YOUR_TEMPLATE_ID';
    const userID = 'YOUR_USER_ID';

    const templateParams = {
      ...formData,
      'g-recaptcha-response': captchaToken,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Form submitted successfully!');
        // Reset form
        setFormData({ name: '', email: '', phone: '', message: '' });
        setCaptchaToken(null);
      }, (error) => {
        console.log('FAILED...', error);
        alert('Form submission failed. Please try again.');
      });
  };

  return (
    <>
     <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="bg2"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">CONTACT</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>CONTACT</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

      <div className="office-info">
        <h2>EGPMCLLC MAIN OFFICE</h2>
        <div className="office">
          <p>Office No. 1606, Tamani Arts Office</p>
          <p>Business Bay, Dubai, U.A.E</p>
          <p>Phone: 00971 4 5808141</p>
        </div>
     

      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name:*
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:*
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone:
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </label>
          <label>
            Message:*
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </label>
          {/* <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6LfdN_MpAAAAAGudcYRlqj9wGGI9zN_0p8HNF51_" // Replace this with your actual site key
              onChange={handleCaptchaChange}
            />
          </div> */}
          <button type="submit" className="send-button">
            <FontAwesomeIcon icon={faPaperPlane} /> Send
          </button>
        </form>
      </div>
     
      </div>
      
    </>
  );
};

export default Contact;
