

import React, { useState } from 'react';
import '../styles/Oil.css';
import img1 from '../assets/vfd.png';
import img2 from '../assets/vd.png';
import img3 from '../assets/uss.png';
import img4 from '../assets/ps.png';
import img5 from '../assets/hs.png';
import img6 from '../assets/gbd.png';
import img7 from '../assets/fyd.png';
import img8 from '../assets/flp2.png';
import img9 from '../assets/flp.png';
import img10 from '../assets/flc5.png';
import img11 from '../assets/flc4.png';
import img12 from '../assets/flc2.png';
import img13 from '../assets/fs.jpg';
import img14 from '../assets/dps6.png';
import img15 from '../assets/ad1.png';
import img16 from '../assets/api.png';

const Oil = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const renderProduct = (product) => {
    let productImage;
    switch (product) {
      case 'VARIABLE FREQUENCY DRIVE':
        productImage = img1;
        break;
      case 'VACUUM DEGASSER':
        productImage = img2;
        break;
      case 'URETHANE SCALPING SCREENS':
        productImage = img3;
        break;
      case 'PYRAMID SCREENS':
        productImage = img4;
        break;
      case 'Hyperpool Series':
        productImage = img5;
        break;
      case 'Gearbox Drive':
        productImage = img6;
        break;
      case 'Full Hydraulic Drive':
        productImage = img7;
        break;
      case 'Flo-Line Primer':
        productImage = img8;
        break;
      case 'Flo-Line Primer II':
        productImage = img9;
        break;
      case 'Flo-Line Cleaner 500':
        productImage = img10;
        break;
      case 'Flo-Line Cleaner 400':
        productImage = img11;
        break;
      case 'Flo-Line Cleaner 2000':
        productImage = img12;
        break;
      case 'Flat Screens':
        productImage = img13;
        break;
      case 'Dual Pool 600 Series':
        productImage = img14;
        break;
      case 'Atmospheric Degasser':
        productImage = img15;
        break;
      case 'API RP 13C (ISO 13501) Compliant Screens':
        productImage = img16;
        break;
      default:
        productImage = img1;
    }
    return (
      <div key={product} className="product">
        <img src={productImage} alt={product} />
        <p>{product}</p>
      </div>
    );
  };

  const productsByTab = [
    ['VARIABLE FREQUENCY DRIVE', 'VACUUM DEGASSER', 'URETHANE SCALPING SCREENS', 'PYRAMID SCREENS', 'Hyperpool Series', 'Gearbox Drive', 'Full Hydraulic Drive', 'Flo-Line Primer II', 'Flo-Line Primer', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Flat Screens', 'Dual Pool 600 Series', 'Atmospheric Degasser', 'API RP 13C (ISO 13501) Compliant Screens'],
    ['VACUUM DEGASSER', 'Atmospheric Degasser'],
    ['VARIABLE FREQUENCY DRIVE', 'Gearbox Drive', 'Full Hydraulic Drive'],
    ['Hyperpool Series', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Dual Pool 600 Series'],
    ['Urethane Scalping Screens', 'Pyramid Screens', 'Hyperpool Series', 'Flo-Line Primer II', 'Flo-Line Cleaner 500', 'Flo-Line Cleaner 400', 'Flo-Line Cleaner 2000', 'Flat Screens', 'Dual Pool 600 Series', 'API RP 13C (ISO 13501) Compliant Screens'],
    ['Flo-Line Primer II', 'Flo-Line Primer']
  ];

  return (
    <>
    <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="oil1"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">PRODUCTS</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>PRODUCTS</span>/<span class='contact'>OIL & GAS</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

      <div className="products-info">
      <a href="/products" className='back-button'>BACK</a>
        <h2>OIL & GAS DRILLING</h2>
        <div className="tabs-wrapper">
          <div className="tabs-container">
            {['All Products', 'Degassers', 'Centrifuges', 'Shakers', 'Screens', 'Scalpers'].map((tabName, index) => (
              <div
                key={index}
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tabName}
              </div>
            ))}
          </div>
        </div>
        <div className="tab-content">
          {productsByTab[activeTab].map((product) => renderProduct(product))}
        </div>
      </div>
    </>
  );
}

export default Oil;
