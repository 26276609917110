// // Home.jsx

// import React, { useEffect, useRef } from 'react';
// import { Link } from "react-router-dom";
// import { Carousel } from 'react-responsive-carousel';
// import img1 from '../assets/mining.jpg';
// import img2 from '../assets/oil.jpg';
// import img3 from '../assets/cst.jpg';
// import img4 from '../assets/govt.jpg';
// import img5 from '../assets/part.jpg';
// import img6 from '../assets/fin.jpg';
// import img7 from '../assets/cons.jpg';
// import img8 from '../assets/social.jpg';
// import img9 from '../assets/transport.jpg';
// import img10 from '../assets/energy.jpg';
// import img11 from '../assets/health.jpg';
// import img12 from '../assets/travel.jpg';
// import img13 from '../assets/corp.jpg';
// import img14 from '../assets/hr.jpg';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import '../styles/Home.css'; // Import your custom CSS for styling

// const Home = () => {
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const container = containerRef.current;
//     const boxes = container.querySelectorAll('.box');

//     const handleScroll = () => {
//       boxes.forEach(box => {
//         const boxTop = box.getBoundingClientRect().top;
//         const windowHeight = window.innerHeight;
//         if (boxTop < windowHeight * 0.8) {
//           box.classList.add('show');
//         }
//       });
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   return (
//     <>
//       <div className="home-container">
//         <MyCarousel />
//         <div className="container" ref={containerRef}>
//           <div className="grid-container">
           
//             <div className="box travel">
//               <img src={img12} alt="Travel" className="image" />
//               <div className="box-content">
//                 <h2 className="heading-text">TRAVEL & TOURISM</h2>
//                 <p className="description" style={{ textAlign: 'center' }}>
//                   Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
//                 </p>
                
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// // const MyCarousel = () => (
// //   <Carousel
// //     autoPlay
// //     infiniteLoop
// //     interval={1000}
// //     showStatus={false}
// //     showThumbs={false}
// //     showArrows={true}
// //     className="custom-carousel"
// //   >
// //     <div className="carousel-slide">
// //       <img src={img1} alt="Slide 1" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img2} alt="Slide 2" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img3} alt="Slide 3" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img4} alt="Slide 4" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img5} alt="Slide 4" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img6} alt="Slide 4" className="carousel-image" />
// //     </div>
// //     <div className="carousel-slide">
// //       <img src={img7} alt="Slide 4" className="carousel-image" />
// //     </div>
// //   </Carousel>
// // );
// const MyCarousel = () => (
//   <Carousel
//     autoPlay
//     infiniteLoop
//     interval={1000}
//     showStatus={false}
//     showThumbs={false}
//     showArrows={true}
//     className="custom-carousel"
//   >
//     <div className="carousel-slide">
//       <img src={img13} alt="Slide 1" className="carousel-image" />
//       <div className="carousel-text">Slide 1 Name</div>
//       <div className="carousel-text1"></div>
//     </div>
//     <div className="carousel-slide">
//       <img src={img6} alt="Slide 2" className="carousel-image" />
//       <div className="carousel-text">Slide 2 Name</div>
//       <div className="carousel-text1"></div>
//     </div>
//     <div className="carousel-slide">
//       <img src={img13} alt="Slide 3" className="carousel-image" />
//       <div className="carousel-text">Slide 3 Name</div>
//       <div className="carousel-text1"></div>
//     </div>
//     <div className="carousel-slide">
//       <img src={img9} alt="Slide 3" className="carousel-image" />
//       <div className="carousel-text">Slide 3 Name</div>
//       <div className="carousel-text1"></div>
//     </div>

//   </Carousel>
// );

// export default Home;
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../assets/mining.jpg';
import img2 from '../assets/oil.jpg';
import img3 from '../assets/cst.jpg';
import img4 from '../assets/govt.jpg';
import img5 from '../assets/part.jpg';
import img6 from '../assets/fin.jpg';
import img7 from '../assets/cons.jpg';
import img8 from '../assets/social.jpg';
import img9 from '../assets/transport.jpg';
import img10 from '../assets/energy.jpg';
import img11 from '../assets/health.jpg';
import img12 from '../assets/travel.jpg';
import img13 from '../assets/corp.jpg';
import img14 from '../assets/hr.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Home.css'; // Import your custom CSS for styling

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const boxes = container.querySelectorAll('.box');

    const handleScroll = () => {
      boxes.forEach(box => {
        const boxTop = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (boxTop < windowHeight * 0.8) {
          box.classList.add('show');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="home-container">
        <MyCarousel />
        <div className="container" ref={containerRef}>
          <div className="grid-container">
            {/* Example of integrating content */}
            <div className="box energy">
  <div className="box-content">
    <h2 className="heading-text">ENERGIC GLOBAL PROJECT MANAGEMENT</h2>
    <p className="description">
      Energic Global Project Management (EGPM) has a great track record of delivering high-profile, high-value projects in vastly different locations around the world. Much of this success is down to the way we manage projects. Our priorities are safety and client satisfaction.
    </p>
    <p>
      Energic Global Project Management (EGPM) is based in Dubai, U.A.E, operating from Office No. 1606, Tamani Arts Office Business Bay. We specialize in delivering comprehensive project management solutions across the international oil and gas industry, ensuring safety, efficiency, and regulatory compliance.
    </p>
    <h3>Services Overview</h3>
    <p>
      Working across the international oil and gas industry, we help our clients unlock the full value of their energy assets. Our service capability spans the oil and gas asset life cycle, from conceptual greenfield developments through to brownfield modifications, late life, and decommissioning.
    </p>
    
  </div>
</div>

            {/* Other boxes can be similarly added */}
          </div>
        </div>
      </div>
    </>
  );
};


const MyCarousel = () => (
  <Carousel
    autoPlay
    infiniteLoop
    interval={1000}
    showStatus={false}
    showThumbs={false}
    showArrows={true}
    className="custom-carousel"
  >
    <div className="carousel-slide">
      <img src={img13} alt="Slide 1" className="carousel-image" />
      <div className="carousel-text">Slide 1 Name</div>
      <div className="carousel-text1"></div>
    </div>
    <div className="carousel-slide">
      <img src={img6} alt="Slide 2" className="carousel-image" />
      <div className="carousel-text">Slide 2 Name</div>
      <div className="carousel-text1"></div>
    </div>
    <div className="carousel-slide">
      <img src={img13} alt="Slide 3" className="carousel-image" />
      <div className="carousel-text">Slide 3 Name</div>
      <div className="carousel-text1"></div>
    </div>
    <div className="carousel-slide">
      <img src={img9} alt="Slide 3" className="carousel-image" />
      <div className="carousel-text">Slide 3 Name</div>
      <div className="carousel-text1"></div>
    </div>

  </Carousel>
);


export default Home;
